<template>
  <div class="filters">
    <span v-for="(f, i) in enabled" :key="i">
      <v-chip :color="f.valid ? 'teal' : 'red'" text-color="white" :close="!f.fixed " @click:close="enabled.splice(i, 1)">
          {{f.name}}&nbsp;

          <v-menu offset-y v-if="getTypeOps(f.type).length > 1">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="filter-op">{{ opNames[f.op] }}</span>
            </template>
            <v-list>
              <v-list-item
                  v-for="op in getTypeOps(f.type)"
                  :key="op"
                  @click="f.op = op"
              >
                <v-list-item-title>{{ opNames[op] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-else>{{opNames[f.op]}}&nbsp;</span>

          <template v-if="opNeedsValue[f.op]">
              <v-menu offset-y v-if="f.type === 'enum'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="filter-op">{{ f.value }}</span>
                </template>
                <v-list>
                    <v-list-item
                        v-for="value in f.choices"
                        :key="value"
                        @click="f.value = value"
                    >
                      <v-list-item-title>{{ value }}</v-list-item-title>
                    </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-else-if="f.type === 'bool'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="filter-op">{{ f.value }}</span>
                </template>
                <v-list>
                  <v-list-item @click="f.value = 'true'">
                    <v-list-item-title>true</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="f.value = 'false'">
                    <v-list-item-title>false</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-else-if="f.type == 'time'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="filter-op">{{ f.value }}</span>
                </template>
                <v-date-picker v-model="f.value" no-title scrollable/>
              </v-menu>
              <input type="text" v-else v-model="f.value" class="filter-value"/>
          </template>
      </v-chip>
    </span>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="teal" outlined rounded>
          <v-icon>add</v-icon>Add filter
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(f, i) in availableFilters" :key="i" @click="enable(f)">
          <v-list-item-title>{{ f.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
function filterValid(f) {
  if (f.type === 'int') {
    if (f.op === 'nz' || f.op === 'pos' || f.op === 'neg') {
      return true;
    }

    // you drunk javascript, go home
    if (!Number.isInteger(Number(f.value))) {
      return false;
    }
    if (f.value !== String(parseInt(Number(f.value), 10))) {
      // eslint-disable-next-line no-param-reassign
      f.value = String(parseInt(Number(f.value), 10));
    }
  }
  if (f.type === 'id') {
    if (f.value === 'null' && f.nullable) {
      return true;
    }
    return !!f.value.match(`^${f.id_prefix}_[123456789abcdefghjklmnpqrstuvxyz]{20}$`);
  }
  return true;
}

function filterToQuery(f) {
  if (f.op === 'eq') {
    return [f.key, f.value];
  }
  if (f.op === 'z') {
    return [`${f.key}`, '0'];
  }
  if (f.op === 'nz') {
    return [`${f.key}:ne`, '0'];
  }
  if (f.op === 'pos') {
    return [`${f.key}:gt`, '0'];
  }
  if (f.op === 'neg') {
    return [`${f.key}:lt`, '0'];
  }
  return [`${f.key}:${f.op}`, f.value];
}

function queryToFilter(filters, k, v) {
  const idx = k.indexOf(':');
  const key = idx === -1 ? k : k.substr(0, idx);
  let op = idx === -1 ? 'eq' : k.substr(idx + 1);
  const f = filters.find((e) => e.key === key);
  if (!f) return null;

  const value = v;
  if (op === 'ne' && value === '0') op = 'nz';
  if (op === 'gt' && value === '0') op = 'pos';
  if (op === 'lt' && value === '0') op = 'neg';

  return {
    ...f,
    op,
    value,
    valid: true,
  };
}

export default {
  props: {
    filters: Array,
    query: Object,
  },
  computed: {
    availableFilters() {
      return this.filters;
    },
  },
  data() {
    return {
      typeOps: {
        int: ['eq', 'ne', 'lt', 'le', 'gt', 'ge', 'nz', 'pos', 'neg'],
        time: ['day', 'day:ne', 'day:gt', 'day:ge', 'day:lt', 'day:le'],
      },
      opNames: {
        eq: '=',
        ne: '!=',
        lt: '<',
        le: '<=',
        gt: '>',
        ge: '>=',
        nz: 'is non-zero',
        pos: 'is positive',
        neg: 'is negative',
        day: 'day =',
        'day:gt': 'day >',
        'day:ne': 'day !=',
        'day:ge': 'day >=',
        'day:lt': 'day <',
        'day:le': 'day <=',
      },
      opNeedsValue: {
        eq: true,
        ne: true,
        lt: true,
        le: true,
        gt: true,
        ge: true,
        nz: false,
        pos: false,
        neg: false,
        day: true,
        'day:gt': true,
        'day:ne': true,
        'day:ge': true,
        'day:lt': true,
        'day:le': true,
      },
      enabled: [],
    };
  },
  watch: {
    query: {
      immediate: true,
      handler() {
        this.enabled = [];
        for (const [k, v] of Object.entries(this.query)) {
          const f = queryToFilter(this.filters, k, v);
          if (f) this.enabled.push(f);
        }
      },
    },
    enabled: {
      deep: true,
      handler() {
        const query = {};
        let valid = true;
        for (const f of this.enabled) {
          const fValid = filterValid(f);
          f.valid = fValid;
          valid = valid && fValid;
          const q = filterToQuery(f);
          const [k, v] = q;
          query[k] = v;
        }
        if (valid) {
          this.$router.replace({ query });
        }
      },
    },
  },
  methods: {
    getTypeOps(type) {
      return this.typeOps[type] || ['eq'];
    },
    enable(f) {
      const op = (this.typeOps[f.type] || ['eq'])[0];
      let value = '0';
      if (f.type === 'string' || f.type === 'id') {
        value = '';
      }
      if (f.type === 'time') {
        value = new Date().toISOString().substr(0, 10);
      }
      if (f.type === 'bool') {
        value = 'true';
      }
      if (f.type === 'enum') {
        [value] = f.choices;
      }
      this.enabled.push({
        ...f,
        op,
        value,
        valid: false,
      });
    },
  },
};
</script>

<style lang="scss">
.filter-op, .filter-value {
    background: rgba(0, 0, 0, 0.1);
    line-height: 34px;
    padding: 0 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
        background: rgba(0, 0, 0, 0.3);
    }
}
</style>
